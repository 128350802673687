import React from "react";
import PropTypes from "prop-types";
import Select from 'react-select';
import {SelectStyleNoInput, conditionOptions} from '../../constants';

const ConditionField = props => {
    let selectedCondition = null;
    if (props.capAsset.condition_code) {
        const label = conditionOptions.filter(c => c.value === props.capAsset.condition_code)[0].label;
        selectedCondition = { value: props.capAsset.condition_code, label };
    }
    return (
        <div className="row">
            <div className="small-3 medium-2 medium-offset-1 columns">
                <label className="inline right">Condition:</label>
            </div>
            <div className="small-4 columns">
                <Select options={conditionOptions}
                        styles={SelectStyleNoInput}
                        maxMenuHeight={200}
                        isSearchable={false}
                        placeholder={'-- Select --'}
                        value={selectedCondition}
                        onChange={props.handleConditionChange} />
            </div>
            <div className="small-1 medium-2 columns end">
            </div>
        </div>
    );
};

ConditionField.propTypes = {
    handleConditionChange: PropTypes.func.isRequired,
    capAsset: PropTypes.object.isRequired
};

export default ConditionField;
