import React, { useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Quagga from '@ericblade/quagga2';

function getMedian(originalArray) {
    if (!originalArray || !originalArray.length) {
        return 0;
    }

    const arr = [...originalArray];
    arr.sort((a, b) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1) {
        return arr[half];
    }
    return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes) {
    const errors = decodedCodes.filter(x => x && (x.error === 0 || x.error)).map(x => x.error);
    const medianOfErrors = getMedian(errors);
    return medianOfErrors;
}

const BarcodeScanner = ({
                     onDetected,
                     scannerRef,
                     onScannerReady,
                     cameraId,
                     facingMode,
                     constraints = { width: 400, height: 300 },
                     locator = { patchSize: 'medium', halfSample: true },
                     numOfWorkers = navigator.hardwareConcurrency || 0,
                     locate = true,
                 }) => {
    const errorCheck = useCallback((result) => {
        if (!onDetected) {
            return;
        }
        const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
        // if Quagga is at least 75% certain that it read correctly, then accept the code.
        if (err < 0.25) {
            onDetected(result.codeResult.code);
        }
    }, [onDetected]);

    const handleProcessed = (result) => {
        if (result) {
            console.info('* quagga onProcessed', result);
            onDetected(result);
        }
    };

    useLayoutEffect(() => {
        console.info('useLayoutEffect');
        Quagga.init({
            inputStream: {
                type: 'LiveStream',
                constraints: {
                    ...constraints,
                    ...(cameraId ? { deviceId: cameraId } : { facingMode })
                },
                target: scannerRef.current,
            },
            locator,
            numOfWorkers,
            decoder: { readers: ['code_39_reader', 'code_128_reader'] },
            locate,
        }, (err) => {
            console.info('Quagga.init callback');
            Quagga.onProcessed(handleProcessed);

            if (err) {
                return console.log('Error starting Quagga:', err);
            }
            if (scannerRef && scannerRef.current) {
                Quagga.start();
                if (onScannerReady) {
                    onScannerReady();
                }
            } else {
                console.info('scannerRef null', scannerRef);
            }
        });

        console.info('Quagga.onDetected(errorCheck);');
        Quagga.onDetected(errorCheck);

        return () => {
            console.info('Quagga.offDetected(errorCheck);');
            Quagga.offDetected(errorCheck);
            Quagga.offProcessed(handleProcessed);
            Quagga.stop();
        };
    }, [cameraId, onDetected, onScannerReady, scannerRef, errorCheck, constraints, locator, locate, handleProcessed]);
    return null;
}

BarcodeScanner.propTypes = {
    onDetected: PropTypes.func.isRequired,
    scannerRef: PropTypes.object.isRequired,
    onScannerReady: PropTypes.func,
    cameraId: PropTypes.string,
    facingMode: PropTypes.string,
    constraints: PropTypes.object,
    locator: PropTypes.object,
    numOfWorkers: PropTypes.number,
    locate: PropTypes.bool,
};

export default BarcodeScanner;