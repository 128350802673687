import React from "react";
import { withRouter } from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {handleCognitoCallback} from "./helpers/cognito";
import ErrorMessage from "./form/ErrorMessage";
import InfoMessage from "./form/InfoMessage";
import Loading from "./Loading";

const buildCognitoLoginUrl = () => {
    const path = `https://${process.env.REACT_APP_AWS_COGNITO_DOMAIN}/login`;
    const clientId = process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID_WEB;
    const cognitoRedirectUrl = window.location.origin;
    return `${path}?response_type=token&client_id=${clientId}&redirect_uri=${cognitoRedirectUrl}`;
}

const redirectToCognitoLogin = () => {
    const targetUrl = buildCognitoLoginUrl();
    window.location.href = targetUrl;
}

const Login = props => {
    let errorMessage = "";
    const url = window.location.href;

    if (url.includes('id_token') || url.includes('access_token')) {
        const successCallback = () => {
            props.history.push("/");
        };
        const failureCallback = error => {
            errorMessage = error;
        };

        handleCognitoCallback({ successCallback, failureCallback });

    } else {

        Auth.currentAuthenticatedUser().then(currentUser => {
            if (!currentUser || !currentUser.signInUserSession) {
                return redirectToCognitoLogin();
            }

            try {
                localStorage.setItem('id_token', currentUser.signInUserSession.idToken.jwtToken);
                localStorage.setItem('netid', currentUser.attributes.name);
            } catch(error) {
                console.error(error);
                errorMessage = error;
            }

            props.history.push("/scan");

        }).catch(error => {
            console.error(error);
            redirectToCognitoLogin();
        });
    }

    return (
        <div id="bodyContainer">


            <div className="row">
                <div className="small-9 small-offset-3 columns end">
                    <InfoMessage infoMessage="Signing in from Cornell" />
                    <Loading />
                    <ErrorMessage errorMessage={errorMessage} />
                </div>
            </div>
        </div>
    );
}

export default withRouter(Login);
