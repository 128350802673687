import React from "react";
import PropTypes from "prop-types";
import FormMessage from "./FormMessage";

const ErrorMessage = props => (
    <FormMessage message={props.errorMessage} className="errorMessage"/>
);

ErrorMessage.propTypes = {
    errorMessage: PropTypes.string
};

export default ErrorMessage;
