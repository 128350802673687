import React from 'react';
import { SelectStyleBuilding } from '../../constants';
import AsyncSelect from 'react-select/async';
import PropTypes from "prop-types";
import { handleKeyDownOverridesForReactSelect, preventEnterKeyDown } from '../helpers/react_select';

const BuildingField = props => {
    const selectedValue = {
        value: props.capAsset.building_code,
        label: props.capAsset[props.buildingAttributeName],
        name: props.capAsset.building_name
    };
    const textValue = selectedValue.label;
    const attributeLabel = props.buildingAttributeLabel;
    const noOptionsMessage = ({inputValue}) =>
            inputValue ? `${attributeLabel} Not Found` : `Please Enter a ${attributeLabel}`;

    return (
        <div className="row">
            <div className="small-3 medium-2 medium-offset-1 columns">
                <label className="inline right">{attributeLabel}:</label>
            </div>
            <div className="small-4 columns" onKeyDown={preventEnterKeyDown}>
                <AsyncSelect
                    classNamePrefix='asset-building-attribute'
                    cacheOptions
                    defaultOptions
                    loadOptions={props.handleBuildingSearch}
                    placeholder=''
                    inputValue={textValue}
                    value={selectedValue}
                    styles={SelectStyleBuilding}
                    noOptionsMessage={noOptionsMessage}
                    openMenuOnFocus={!textValue}
                    openMenuOnClick={!textValue}
                    onKeyDown={handleKeyDownOverridesForReactSelect}
                    onInputChange={props.handleBuildingInputChange}
                    onChange={props.handleBuildingChange} />
            </div>
            <div className="small-1 medium-2 columns end">
            </div>
        </div>
    );
};

BuildingField.propTypes = {
    handleBuildingInputChange: PropTypes.func.isRequired,
    handleBuildingSearch: PropTypes.func.isRequired,
    handleBuildingChange: PropTypes.func.isRequired,
    capAsset: PropTypes.object.isRequired,
    buildingAttributeName: PropTypes.string.isRequired,
    buildingAttributeLabel: PropTypes.string.isRequired
};

export default BuildingField;
