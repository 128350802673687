const getKfsErrorMessage = (err) => {
    const defaultErrorMessage = `There was an unexpected error while retrieving buildings from Cornell's Financial System`;
    if (err.body && err.body.error) {
        return err.body.error;
    }
    if (!err || err.status === 500) {
        return defaultErrorMessage + '  (KFS error code 500)';
    }
    if (err.status === 404) {
        return 'Could not find Asset Tag in KFS';
    }
    if (err.status === 403) {
        return `Unauthorized (403)`;
    }
    if (err.status === 400) {
        return getApiErrorMessage(err);
    }
    console.error(`Unhandled Status ${err.status}`);
    return `${defaultErrorMessage} \n [Unknown ${err.status}] ${getApiErrorMessage(err)}`;
};

const getApiErrorMessage = error => {
    let ret = "An Unexpected Error Occurred";
    if (error && error.body && error.body.error) {
        if (typeof error.body.error == 'string') {
            ret = error.body.error;
        } else if (error.body.error.message) {
            ret = error.body.error.message;
        }
    } else if (error) {
        ret += ` ${error.toString()}`;
    }
    return ret;
};

const throwApiError = (error, apiLogMessage) => {
    if (!error || error.toString().toLowerCase().includes("failed to fetch")) {
        const errorMessage = { status: 404, message: 'Could not reach KFS (may be down).' };
        throw errorMessage;
    }

    error.message = getKfsErrorMessage(error);
    console.error(`${error && error.status} ${apiLogMessage}`);
    console.error(error);
    throw error;
}

export async function makeApiCall({ method = 'get', path, requestBody, idToken }) {
    const requestPath = process.env.REACT_APP_KFS_API_BASE_URL + path;
    const headers = {
        'Content-Type': 'application/json',
        cognito_id_token: idToken
    };

    const fetchParams = { method, headers };
    if (requestBody) {
        fetchParams.body = JSON.stringify(requestBody);
    }
    const apiLogMessage = `${method.toUpperCase()} ${requestPath}`;
    console.info(apiLogMessage);
    try {
        const response = await fetch(requestPath, fetchParams);
        if (response.status >= 400) {
            const {status, statusText, url} = response;
            const body = await response.json();
            const error = {status, statusText, url, body};
            throwApiError(error, apiLogMessage);
        } else {
            return await response.json();
        }
    } catch (error) {
        throwApiError(error, apiLogMessage);
    }
}
