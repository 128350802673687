import React from "react";
import PropTypes from "prop-types";
import BarcodeScanner from "../BarcodeScanner";

const AssetTagField = props => {

    const iconPath = `images/${props.scanning ? 'cancel' : 'camera'}_icon.png`;
    const iconAlt = props.scanning ? 'Cancel' : 'Scan Barcode';

    return (
        <>
            <div className="row">
                <div className="small-3 medium-2 medium-offset-1 columns">
                    <label className="inline right">Asset Tag:</label>
                </div>
                <div className="small-4 medium-3 columns">
                    <input
                        id="assetTagInput"
                        type="text"
                        name="asset_tag"
                        ref={props.assetTagInputRef}
                        value={props.capAsset.asset_tag}
                        onChange={props.handleAssetTagChange}
                        onKeyPress={props.handleAssetTagKeyPress}
                        onBlur={props.handleAssetTagKeyPress}
                        required
                    />
                </div>
                <div className="small-2 columns end">
                    <img id="barcodeIcon" alt={iconAlt} src={iconPath} onClick={props.scanButtonClicked} />
                </div>
            </div>

            <div className={props.scanning ? undefined : 'hidden' } ref={props.scannerRef}>
                <canvas className="drawingBuffer" width="400" height="300" />
                {props.scanning ? <BarcodeScanner scannerRef={props.scannerRef} onDetected={props.onBarcodeDetected} /> : null}
            </div>

            <div className="row">
                <div className="small-3 medium-2 medium-offset-1 columns">
                    <label className="inline right">Description:</label>
                </div>
                <div className="small-8 columns padding-top">
                    {props.capAsset.capital_asset_description}
                </div>
                <div className="small-1 medium-2 columns end">
                </div>
            </div>

            <div className="row">
                <div className="small-3 medium-2 medium-offset-1 columns">
                    <label className="inline right">Serial Number:</label>
                </div>
                <div className="small-8 columns padding-top">
                    {props.capAsset.serial_number}
                </div>
                <div className="small-1 medium-2 columns end">
                </div>
            </div>

            <div className="row">
                <div className="small-3 medium-2 medium-offset-1 columns">
                    <label className="inline right">Organization:</label>
                </div>
                <div className="small-8 columns padding-top">
                    {props.capAsset.organization}
                </div>
                <div className="small-1 medium-2 columns end">
                </div>
            </div>

        </>
    );
};

AssetTagField.propTypes = {
    scanButtonClicked: PropTypes.func.isRequired,
    onBarcodeDetected: PropTypes.func.isRequired,
    handleAssetTagChange: PropTypes.func.isRequired,
    handleAssetTagKeyPress: PropTypes.func.isRequired,
    capAsset: PropTypes.object.isRequired,
    assetTagRef: PropTypes.object,
    scannerRef: PropTypes.object,
    scanning: PropTypes.bool.isRequired,
    isCameraAvailable: PropTypes.bool.isRequired
};

export default AssetTagField;
