import React from "react";
import PropTypes from "prop-types";

const FormMessage = props => (
    <>
        {props.message && props.message.length && <br/>}
        <label className={props.className}>{props.message}</label>
        {props.message && props.message.length && <br/>}
    </>
);

FormMessage.propTypes = {
    message: PropTypes.string,
    className: PropTypes.string.isRequired
};

export default FormMessage;
