import React from 'react';
import PropTypes from "prop-types";
import BuildingField from './BuildingField';

const BuildingCodeField = props => (
    <BuildingField
        buildingAttributeName='building_code'
        buildingAttributeLabel='Building Code'
        handleBuildingInputChange={props.handleBuildingInputChange}
        handleBuildingSearch={props.handleBuildingSearch}
        handleBuildingChange={props.handleBuildingChange}
        capAsset={props.capAsset}/>
);

BuildingCodeField.propTypes = {
    handleBuildingInputChange: PropTypes.func.isRequired,
    handleBuildingSearch: PropTypes.func.isRequired,
    handleBuildingChange: PropTypes.func.isRequired,
    capAsset: PropTypes.object.isRequired
};

export default BuildingCodeField;
