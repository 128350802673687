export const contactHelpMessage = 'If you experience any difficulties, please contact Karen Pappalardo at 255.5657, kp378@cornell.edu';

export const canceledOperationMessage = 'Operation was canceled';

export const conditionOptions = [
    { value: 'E', label: 'Excellent' },
    { value: 'G', label: 'Good' },
    { value: 'F', label: 'Fair' },
    { value: 'P', label: 'Poor' },
    { value: 'S', label: 'Scrap-S' },
    { value: 'E1', label: 'Used-Recondition-Excellent' },
    { value: 'E2', label: 'Used-Recondition-Good' },
    { value: 'E3', label: 'Used-Recondition-Fair' },
    { value: 'E4', label: 'Used-Recondition-Poor' },
    { value: 'N1', label: 'New- Excellent' },
    { value: 'N2', label: 'New- Good' },
    { value: 'N3', label: 'New- Fair' },
    { value: 'N4', label: 'New- Poor' },
    { value: 'O1', label: 'Used-Useable-w/o Repair-Excellent' },
    { value: 'O2', label: 'Used-Useable-w/o Repair-Good' },
    { value: 'O3', label: 'Used-Useable-w/o Repair-Fair' },
    { value: 'O4', label: 'Used-Useable-w/o Repair-Poor' },
    { value: 'R1', label: 'Used-Repair Required- Excellent' },
    { value: 'R2', label: 'Used-Repair Required- Good' },
    { value: 'R3', label: 'Used-Repair Required- Fair' },
    { value: 'R4', label: 'Used-Repair Required- Poor' },
    { value: 'SS', label: 'Salvage' },
    { value: 'X', label: 'Scrap-X' },
    { value: 'XX', label: 'Scrap-XX' },
    { value: 'UN', label: 'Unconverted Condition' },
    { value: 'BO', label: 'Buildings & Other Non-Moveable' }
];

export const SelectStyleNoInput = Object.freeze({
    container: (provided) => ({
        ...provided,
        display: 'inline-block',
        width: '250px',
        minHeight: '1px',
        textAlign: 'left',
        border: 'none',
    }),
    control: (provided) => ({
        ...provided,
        border: '1px solid #cccccc',
        borderRadius: '0',
        minHeight: '1px',
        height: '42px',
    }),
    input: (provided) => ({
        ...provided,
        minHeight: '1px',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        minHeight: '1px',
        paddingTop: '0',
        paddingBottom: '0',
        color: '#757575'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    }),
    clearIndicator: (provided) => ({
        ...provided,
        minHeight: '1px',
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '0px',
        marginTop: '0px'
    }),
    valueContainer: (provided) => ({
        ...provided,
        minHeight: '1px',
        height: '40px',
        paddingTop: '0',
        paddingBottom: '0',
    }),
    singleValue: (provided) => ({
        ...provided,
        minHeight: '1px',
        paddingBottom: '2px',
    }),
});

export const SelectStyleRoom = Object.freeze({
    container: (provided) => ({
        ...provided,
        display: 'inline-block',
        width: '250px',
        minHeight: '1px',
        textAlign: 'left',
        border: 'none',
    }),
    control: (provided) => ({
        ...provided,
        border: '1px solid #cccccc',
        borderRadius: '0',
        minHeight: '1px',
        height: '42px',
    }),
    input: (provided) => ({
        ...provided,
        minHeight: '1px',
        boxShadow: 'none',
        width: '100%'
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        minHeight: '1px',
        paddingTop: '0',
        paddingBottom: '0',
        color: '#757575'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    }),
    clearIndicator: (provided) => ({
        ...provided,
        minHeight: '1px',
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '0px',
        marginTop: '0px'
    }),
    valueContainer: (provided) => ({
        ...provided,
        minHeight: '1px',
        height: '40px',
        paddingTop: '0',
        paddingBottom: '0',
    }),
    singleValue: (provided) => ({
        ...provided,
        minHeight: '1px',
        paddingBottom: '2px',
    }),
});

export const SelectStyleBuilding = Object.freeze({
    container: (provided) => ({
        ...provided,
        display: 'inline-block',
        width: '250px',
        minHeight: '1px',
        textAlign: 'left',
        border: '1px solid #cccccc'
    }),
    control: (base, state) => ({
        ...base,
        border: '0 !important',
        // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
    input: (provided) => ({
        ...provided,
        minHeight: '1px',
        boxShadow: 'none',
        width: '100%',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        display: 'none'
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '0px',
        marginTop: '0px'
    }),
    valueContainer: (provided) => ({
        ...provided,
        minHeight: '1px',
        height: '40px',
        paddingTop: '0',
        paddingBottom: '0',
    }),
    singleValue: (provided) => ({
        ...provided,
        minHeight: '1px',
        paddingBottom: '2px',
    }),
});
