import React from "react";
import PropTypes from "prop-types";
import Select from 'react-select';
import { SelectStyleRoom } from '../../constants';
import { handleKeyDownOverridesForReactSelect, preventEnterKeyDown } from '../helpers/react_select';

const RoomField = props => {

    let selectedRoom = { label: "Select Building First", value: '' };
    if (props.capAsset.building_code && props.rooms.length) {
        selectedRoom = {value: props.capAsset.room_number, label: props.capAsset.room_number};
    }
    return (
        <div className="row">
            <div className="small-3 medium-2 medium-offset-1 columns">
                <label className="inline right">Room:</label>
            </div>
            <div className="small-4 columns noPaddingRight" onKeyDown={preventEnterKeyDown}>
                <Select classNamePrefix="asset-room-attribute"
                        options={props.rooms}
                        styles={SelectStyleRoom}
                        isSearchable={true}
                        isDisabled={props.rooms.length === 0}
                        onKeyDown={handleKeyDownOverridesForReactSelect}
                        onInputChange={props.handleRoomInputChange}
                        onChange={props.handleRoomChange}
                        inputValue={selectedRoom.label}
                        value={selectedRoom} />
            </div>
            <div id="loadingContainer" className="small-1 medium-2 columns end">
                { props.loadingRoomLookup ? <img id="fieldLoadingImage" src="/images/loading_field.gif" alt="Content Loading" /> : '' }
            </div>
        </div>
    );
};

RoomField.propTypes = {
    handleRoomInputChange: PropTypes.func.isRequired,
    handleRoomChange: PropTypes.func.isRequired,
    rooms: PropTypes.array.isRequired,
    capAsset: PropTypes.object.isRequired,
    loadingRoomLookup: PropTypes.bool.isRequired
};

export default RoomField;
