import React from "react";
import PropTypes from "prop-types";

const BannerMessage = props => {
    if (!props.bannerMessage) {
        return "";
    }

    return (
        <div className="small-8 small-centered columns">
            <div className="alert-box secondary radius">
                <span id="bannerMessage">{props.bannerMessage}</span>
            </div>
        </div>
    );
};


BannerMessage.propTypes = {
    bannerMessage: PropTypes.string.isRequired
};

export default BannerMessage;
