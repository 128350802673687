import {canceledOperationMessage} from "../../constants";

function handleHomeKeyForReactSelect(inputField, shiftKeyIsHeld) {
    let { selectionStart, selectionEnd, selectionDirection } = inputField;
    if (shiftKeyIsHeld) {
        if (selectionStart !== selectionEnd && selectionDirection === 'forward') {
            selectionEnd = selectionStart;
        }
        selectionDirection = 'backward';
    } else {
        selectionEnd = 0;
    }
    selectionStart = 0;
    inputField.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
}

function handleEndKeyForReactSelect(inputField, shiftKeyIsHeld) {
    let { selectionStart, selectionEnd, selectionDirection } = inputField;
    const textValue = inputField.value || '';
    const endPosition = textValue.length;
    if (shiftKeyIsHeld) {
        if (selectionStart !== selectionEnd && selectionDirection === 'backward') {
            selectionStart = selectionEnd;
        }
        selectionDirection = 'forward';
    } else {
        selectionStart = endPosition;
    }
    selectionEnd = endPosition;
    inputField.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
}

export function handleKeyDownOverridesForReactSelect(event) {
    const key = event.key;
    const inputField = event.target;
    if (!event.ctrlKey) {
        if (key === 'Home') {
            event.preventDefault();
            handleHomeKeyForReactSelect(inputField, event.shiftKey);
        } else if (key === 'End') {
            event.preventDefault();
            handleEndKeyForReactSelect(inputField, event.shiftKey);
        }
    }
}

export function preventEnterKeyDown(event) {
    if (event.key === 'Enter') {
        event.preventDefault();
    }
}

export class DelayedCancelableOperation {
    constructor(asyncOperationFunc, delay = 1000) {
        this.currentTimeoutId = null;
        this.currentRejectFunc = null;
        this.asyncOperationFunc = asyncOperationFunc;
        this.delay = delay;
        this.cancelPriorRunIfPending = this.cancelPriorRunIfPending.bind(this);
        this.run = this.run.bind(this);
    }

    cancelPriorRunIfPending() {
        if (this.currentTimeoutId) {
            clearTimeout(this.currentTimeoutId);
            this.currentTimeoutId = null;
        }
        if (this.currentRejectFunc) {
            const currentRejectFunc = this.currentRejectFunc;
            this.currentRejectFunc = null;
            try {
                currentRejectFunc(canceledOperationMessage);
            } catch (error) {
                console.error(error);
            }
        }
    }

    run(...asyncOperationArgs) {
        return new Promise((resolve, reject) => {
            this.cancelPriorRunIfPending();
            this.currentRejectFunc = reject;
            this.currentTimeoutId = setTimeout(async () => {
                this.currentRejectFunc = null;
                this.currentTimeoutId = null;
                let result = null;
                try {
                    result = await this.asyncOperationFunc(...asyncOperationArgs);
                } catch (error) {
                    reject(error);
                    return;
                }
                resolve(result);
            }, this.delay);
        });
    }
}
