import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import NotFound from "./NotFound";
import Home from "./Home";
import Login from "./Login";
import Signout from "./Signout";

const Router = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/signout" component={Signout} />
            <Route path="/scan" component={Home} />
            <Route exact path="/" component={Login} />
            <Route component={NotFound} />
        </Switch>
    </BrowserRouter>
);

export default Router;
