import React from "react";

const isDevUrl = url => {
    return url.includes('dev') || url.includes('kfspts') || url.includes('localhost') || url.includes('192');
}

const TestEnvironmentBanner = () => {

    const url = window.location.href.toLowerCase();

    if (isDevUrl(url)) {
        return (
            <div id="testEnvironmentBanner" className="container">
                <div className="row">
                    <span className="columns">THIS IS A TEST ENVIRONMENT</span>
                </div>
            </div>
        );
    }

    return <></>;
}

export default TestEnvironmentBanner;
