import {CognitoAuth} from "amazon-cognito-auth-js";
import {Auth} from "aws-amplify";

export function handleCognitoCallback({ successCallback, failureCallback }) {
    const currentUrl = window.location.href;
    console.info(currentUrl);

    const signInUrl = window.location.origin;
    const signOutUrl = signInUrl.replace('cognito_callback', 'signout');

    const authData = {
        ClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID_WEB,
        AppWebDomain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
        TokenScopesArray: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
        RedirectUriSignIn: signInUrl,
        RedirectUriSignOut: signOutUrl,
        UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
        AdvancedSecurityDataCollectionFlag: false
    };
    const cognitoAuth = new CognitoAuth(authData);

    cognitoAuth.userhandler = {
        onSuccess: result => {
            console.info('userhandler.onSuccess', result);
            Auth.currentAuthenticatedUser({ bypassCache: true }).then(currentUser => {
                console.info(currentUser);
                successCallback(result);
            }).catch(error => {
                console.error('currentAuthenticatedUser', error);
                failureCallback(error);
            });
        },
        onFailure: error => {
            console.error('userhandler.onFailure', error);
            failureCallback(error);
        }
    };

    try {
        cognitoAuth.parseCognitoWebResponse(currentUrl);
    } catch (error) {
        console.error('cognitoAuth.parseCognitoWebResponse', error);
        failureCallback(error);
    }
}
