import React from "react";
import { Auth } from 'aws-amplify';

class Signout extends React.Component {
    componentDidMount() {

        Auth.signOut().then(result => {
            console.info(result);
        }).catch(error => {
            console.error(error);
        });
    }

    render() {

        return (
            <div id="bodyContainer">
                <br/>

                <h1>Signed Out</h1>
            </div>
        );
    }
}

export default Signout;
