import React from "react";
import PropTypes from "prop-types";
import FormMessage from "./FormMessage";

const InfoMessage = props => (
    <FormMessage message={props.infoMessage} className="infoMessage"/>
);

InfoMessage.propTypes = {
    infoMessage: PropTypes.string
};

export default InfoMessage;
