import React from "react";

const Loading = props => (
    <>
        <div className="row">
            <div className="small-2 columns"></div>
            <div className="small-8 columns">
                <img src="/images/loading.gif" alt="Content Loading" />
            </div>
            <div className="small-2 columns"></div>
        </div>
        <div className="row">
            <div className="small-2 columns"></div>
            <div className="small-8 columns">
                <p>{props.loadingMessage}</p>
            </div>
            <div className="small-2 columns"></div>
        </div>
    </>
);

export default Loading;
