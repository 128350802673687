import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./components/Router";
import TestEnvironmentBanner from "./components/TestEnvironmentBanner";
import "./css/foundation.css";
import "./css/app.css";
import "./css/normalize.css";
import 'font-awesome/css/font-awesome.min.css';
import { Amplify } from 'aws-amplify';

Amplify.configure({
    Auth: {
        mandatorySignInId: false,
        region: process.env.REACT_APP_AWS_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID_WEB
    }
});

const feedbackUrl = `mailto:${process.env.REACT_APP_FEEDBACK_EMAIL}?Subject=Capital%20Asset%20Inventory%20Website%20Feedback`;

const mainContent = (
    <>
        <div id="cornellHeader" className="container height75">
            <div className="row">
                <div className="large-6 columns">
                    <div id="cu-logo">
                        <a id="insignia-link" href="https://www.cornell.edu/">
                            <img src="/images/logo_cornell_dfa.gif"
                                 alt="Cornell University Division of Financial Affairs"/>
                        </a>
                    </div>
                </div>
                <div className="large-6 columns">
                </div>
            </div>
        </div>
        
        <TestEnvironmentBanner/>

        <div id="headerBanner" className="container height123">
            <div className="row display">
                <div className="large-4 columns">
                    <div id="siteHeaderName">
                        <a href="/">
                            <h1 id="procurementServicesHeader" className="marginTop0">Capital Asset Inventory</h1>
                        </a>
                    </div>
                </div>
                <div className="large-8 columns">
                    <span className="hide-for-medium hide-for-small" id="siteHeaderImage"></span>
                </div>
            </div>
        </div>

        <Router/>

        <br/>
        <br/>

        <div id="siteFooter" className="show-for-medium-up">
            <div id="footer-content">
                <ul id="footer-nav">
                    <li><a href="http://www.dfa.cornell.edu/dfa/">DFA Home</a></li>
                    <li><a href={feedbackUrl}>Feedback</a></li>
                </ul>
            </div>
        </div>
    </>
);

ReactDOM.createRoot(document.querySelector("#main")).render(mainContent);
